// @import url("https://fonts.googleapis.com/css2?family=Lato:wght@300&display=swap");

$border-style: 7px solid #A9A9A9
$border-space: 27px

* 
    z-index: 10


html
    width: 100%
    height: 100%

body 
    width: 100%
    height: 100%
    margin: 0
    padding: 0
    // background-color: rgb(49, 48, 48);
    background-color: #5A5A5A
    font-family: 'Lato', sans-serif
    font-family: 'Open Sans', sans-serif

h1 
    color: #fff

// .main-component 
//     position: absolute
//     width: 100%
//     height: 100%

    