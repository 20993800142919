$border-style: 7px solid #A9A9A9
$border-space: 27px    


.home-section-wrapper
    height: 100vh

.right-elements-background 
    display: flex
    align-items: center
    position: relative
    width: 50%
    height: 100%
    float: right
    overflow: hidden

    img
        width: calc(108%)
        padding-left: 10px
        // margin-left: 30px

.turquoise-background
    background-color: #AAE5E2

.left-elements-background
    display: flex
    position: absolute
    align-items: center
    justify-content: flex-end
    width: 50%
    height: 100%
    float: left

    .header-container
        position: absolute
        width: 65%
        margin-right: 70px
        margin-bottom: 70px

        h1
            text-align: right
            padding: 0
            margin: 0
            font-size: 96px
            text-shadow: 1px 1px 2px #494949


.overlay-element
    z-index: 1000
    position: absolute
    width: 300px
    height: 180px

.bottom-left 
    position: absolute
    border-left: $border-style
    border-bottom: $border-style
    bottom: $border-space
    left: calc($border-space + 100px)

.top-right 
    position: absolute
    border-right: $border-style
    border-top: $border-style
    top: $border-space
    right: calc($border-space + 100px)

.main-header-wrapper 
    width: 100%
    height: calc(100% - 54px)

.main-header 
    display: flex
    align-items: center
    justify-content: center
    flex-direction: column
    padding: 10px
    width: 100%

.main-header h1
    font-family: "Lato", sans-serif
    font-size: 80px
    margin-top: 100px
    font-weight: bold

.main-header-line1 
    margin-left: 70px

.sub-header
    display: flex
    align-items: center
    justify-content: right
    margin-left: 10%

.sub-header p
    margin: 0
    margin-bottom: 5px
    margin-top: 5px
    padding: 0
    font-size: 22px
    font-weight: 600
    text-align: right
    padding-right: 20px
    color: rgb(0, 0, 0)
    background-color: rgba(0, 255, 255, 0.678)
    width: 320px
    transform: skewX(-20deg)

.laptop-size

    .header-container

        h1
            font-size: 60px

@media (max-width: 720px) 
    .main-header h1 
        padding-left: 5%
