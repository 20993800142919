.ant-work-drawer

    .slick-dots

        button
            background: black

    .ant-drawer-content
        background: #3BB3AA

    .description-wrapper
        display: flex
        flex-direction: row
        justify-content: space-between
        padding: 20px 40px 20px 5px
        font-weight: bold

        p
            font-size: 16px
            color: #575656


        .info, .stack
            display: flex
            flex-direction: column

            div
                display: flex
                flex-direction: row
                align-items: center

            svg
                font-size: 50px

            h2
                font-weight: 700
                padding-left: 20px
                border-left: 6px solid #303030
                color: #f1f1f1

            .description
                color: #303030

            .github-link
                width: 130px
                height: 45px
                margin-left: 10px
                margin-bottom: 20px
                background: #353535
                display: flex
                justify-content: center
                align-items: center
                border: 2px solid white
                font-family: "Lucida Console", "Courier New", monospace
                color: #fff
                -webkit-box-shadow: -5px 5px 0px -0px #ffffff
                -moz-box-shadow: -5px 5px 0px -0px #ffffff
                box-shadow: -5px 5px 0px -0px #ffffff

            svg
                font-size: 15px
                margin-left: 5px

        .info 
            padding-right: 10px

.work-carousel-img
    width: 100%
    
    img
        width: 100%
        height: 50vh
        object-fit: contain
    
.work-section
    position: relative
    width: 100%
    height: 100vh
    background: #5A5A5A
    border-top: 3px solid white

    .work-section-wrapper
        
        // table
        width: 100%
        height: 100%
        display: flex
        align-items: center

        .row
            height: 773px
            width: 100%
            margin: 0px 100px
            display: flex
            flex-direction: row
            justify-content: center
            padding: 0
            overflow-x: visible
            overflow-y: hidden

            .work-column
                width: 250px
                height: 773px
                display: flex
                flex-direction: column
                align-items: center
                justify-content: center
                clip-path: polygon(27% 13%, 100% 2%, 100% 100%, 27% 85%)
                color: black
                
                .work-no
                    position: absolute
                    font-size: 50px
                    color: white
                    z-index: 1001
                    margin-left: 50px

                .work-no-odd
                    color: black

                img
                    object-fit: contain

                .work-column-style-line
                    position: absolute
                    height: 70%
                    width: 250px
                    border-right: 10px solid #AAE5E2
                    z-index: 1000
                
                .work-line-odd
                    border-right: 10px solid #FADB67

                .work-image-even
                    height: 773px
                    width: 100%
                
                .vop-demo
                    height: 773px
                    position: absolute

            .work-column:hover , .work-column-odd:hover
                height: 700px
        
    .page-title-header
        position: absolute
        z-index: 1000
        font-size: 50px
        color: #fff
        top: 0
        margin-left: 127px

.laptop-size

    .work-section-wrapper
        
        .row

            .work-column

                .vop-demo
                    height: 500px

                img
                    height: 500px
                

