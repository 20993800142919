.contact-section
    position: relative
    width: 100%
    height: 100vh
    border-top: 3px solid white
    
    
    .right-elements-background
        background-color: #5A5A5A
        display: flex
        flex-direction: column
        justify-content: center
        align-items: flex-start

        .contact-name
            display: flex
            flex-direction: row
            align-items: flex-end
            border-left: 2px solid #AAE5E2
            border-bottom: 2px solid #AAE5E2
            height: 40%
            font-size: 48px
            font-weight: bold
            color: #3AA1AA
            margin-bottom: 40px
            padding: 20px
            padding-left: 30px

        .contact-email
            font-size: 24px
            font-weight: bold
            color: #313030
            border-left: 2px solid #AAE5E2
            border-bottom: 2px solid #AAE5E2
            border-top: 2px solid #AAE5E2
            padding: 20px
            padding-left: 30px


        .contact-wrapper
            height: 45%
            padding-left: 20px
            display: flex
            flex-direction: column
            border-left: 2px solid #AAE5E2
            border-top: 2px solid #AAE5E2
            padding: 20px
            margin-top: 40px

            .contact-button
                width: 130px
                height: 45px
                margin-left: 10px
                background: #353535
                display: flex
                justify-content: center
                align-items: center
                border: 2px solid white
                font-family: "Lucida Console", "Courier New", monospace
                color: #fff
                -webkit-box-shadow: -5px 5px 0px -0px #ffffff
                -moz-box-shadow: -5px 5px 0px -0px #ffffff
                box-shadow: -5px 5px 0px -0px #ffffff
                
                svg
                    font-size: 15px
                    margin-left: 5px
                
            .icon-wrapper
                padding-top: 20px
                padding-left: 0
                
                a
                    color: inherit
                    padding: 0px 3px

                .linkedin
                    color: #0a66c2

                .medium
                    color: #fff

                svg
                    font-size: 47px

                .stack-overflow
                    color: #f48224b0

    .left-elements-background
        background-color: #00000083

        .header-container
            margin-left: 30px
            margin-bottom: 50px

            h1
                font-size: 96px
                color: #FADB67
                padding: 0
                margin: 0
                text-shadow: 1px 1px 2px #494949

    .page-title-header
        position: absolute
        z-index: 1000
        font-size: 50px
        color: #fff
        top: 0
        margin-left: 127px

.laptop-size

    .left-elements-background

        .header-container

            h1
                font-size: 60px