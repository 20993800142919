$medium: 900px
$border-style: 5px solid #06283D
$border-space: 0px
$graph-height: 80%

body 

.text
    color: #aaaaaa
    
.grey-background
    background: #5A5A5A
    
.profile-section 
    width: 100%
    height: 100vh
    background: #5A5A5A

    border-top: 3px solid white

    .right-elements-background
        background-color: #5A5A5A

        .header-container
            margin-left: 30px
            margin-bottom: 50px

            h1
                font-size: 96px
                color: #AAE5E2
                padding: 0
                margin: 0
                text-shadow: 1px 1px 2px #494949

    .left-elements-background
        background-color: #3BB3AA

        .profile-info-scroll-section::-webkit-scrollbar
            display: none

        .profile-info-scroll-section
            display: flex
            flex-direction: column
            width: 400px
            height: 600px
            margin-right: 50px
            -ms-overflow-style: none
            scrollbar-width: none
            justify-content: center

            .profile-info-scroll-item
                font-weight:500
                color: #000000e1
                font-size: 16px
                margin: 0px 0px 15px 0px
                padding: 15px 15px 15px 0px
                text-align: right
                border-right: 1px solid #4d4d4d
                // border-top: 1px solid #0000007e

            .profile-info-scroll-item:nth-child(even)
                color: white

            .first-element
                font-size: 24px
                font-weight: bold
                border: none
                color: #494949
                border-right: 1px solid #0000007e
                // border-bottom: 1px solid #0000007e
                padding: 20px 15px 15px 0px
                margin-bottom: 17px


    .page-title-header
        position: absolute
        z-index: 1000
        font-size: 50px
        color: #fff
        margin-left: 127px


    @media (max-width: 540px) 
        height: auto

    .profile-section

        @media (max-width: 800px) 
            margin: 0px
            margin-bottom: 10px

        .ant-divider
            .ant-divider-inner-text
                font-size: 2.5em
                color: #256D85
                letter-spacing: 2px
                font-weight: bold

        .profile-header-wrapper
            margin-bottom: 0
            padding-bottom: 0
            display: flex
            justify-content: flex-start
            align-items: center

            .profile-header
                letter-spacing: 2px
                font-weight: bold

        .ant-divider
            margin: 0

        .profile-body-wrapper
            width: 100%
            height: 90%
            display: flex
            justify-items: center
            justify-content: center

            .profile-body-border-wrapper
                position: relative
                width: 60%
                height: 100%
                padding: 25px 25px 25px 25px
                display: flex
                justify-items: center
                justify-content: center

                @media (max-width: 1375px) 
                    width: 80%

                @media (max-width: 800px) 
                    width: 100%

                .overlay-element
                    position: absolute
                    width: 150px
                    height: 100px

                .top-left 
                    border-left: $border-style
                    border-top: $border-style
                    top: $border-space
                    left: $border-space


                .top-right 
                    border-right: $border-style
                    border-top: $border-style
                    top: $border-space
                    right: $border-space
                    width: 300px

                    @media (max-width: 800px) 
                        top: 10px
                        right: 10px
                        width: calc(100vw - 30px)

                .bottom-left 
                    border-left: $border-style
                    border-bottom: $border-style
                    bottom: $border-space
                    left: $border-space
                    width: 300px

                    @media (max-width: 500px) 
                        bottom: 10px
                        left: 10px
                        width: calc(100vw - 30px)

                .bottom-right 
                    border-right: $border-style
                    border-bottom: $border-style
                    bottom: $border-space
                    right: $border-space

                .ant-card
                    width: 100%
                    background-color: rgb(49, 48, 48)

                .ant-card-body
                    width: 100%
                    height: calc(100% - 58px)

                .profile-body-sub-wrapper 
                    display: flex
                    flex-direction: row

                    @media (max-width: 660px) 
                        flex-direction: column
                        justify-content: center
                        align-items: center

                    .profile-body-content-left
                        width: 50%

                        @media (max-width: 660px) 
                            width: 100%

                    .ant-divider
                        height: auto

                    .profile-body-content-right
                        
                        width: 50%
                        margin: 15px
                        margin-right: 50px

                        @media (max-width: 660px) 
                            margin-left: 50px
                            width: 90%
.laptop-size

    .right-elements-background

        .header-container

            h1
                font-size: 60px

    .left-elements-background

        .profile-info-scroll-section

            .profile-info-scroll-item
                font-size: 14px

            .first-element
                font-size: 16px

        
                            
                        

